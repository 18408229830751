<template>
    <div class="appEnd">
        <el-image class="img" src="../app/app006.webp" />
        <div class="four">
            <div class="a1">
                <div class="a11">
                    <span class="a111">首页</span>
                    <span class="a112" @click="MovenTsgn(0, 0.4)">特色功能 </span>
                </div>
                <div class="a11">
                    <span class="a111">APP下载 </span>
                    <span class="a112" @click="MovenTsgn(1, 0.5)">快速安装 </span>
                </div>
            </div>
            <div class="a1">
                <div class="end">
                    <span class="end1"> 服务信息</span>
                    <span class="end2" @click="MovenTsgn(2, 0.1)">公司简介 </span>
                    <span class="end2" @click="MovenTsgn(2, 0.2)">价值体系 </span>
                    <span class="end2" @click="MovenTsgn(2, 0.5)">服务热线</span>
                </div>
                <div class="end21">
                    <div class="lef">
                        <span class="end211">微信公众号 </span>
                        <div class="imgEl" style="margin-top: 10px;">
                            <el-image style="width: 60px; height: 60px" src="../web/BDs2vSLpNK.jpg" />
                            <span>关注获取资讯 </span>
                        </div>
                    </div>
                    <div class="rig">
                        <span class="end211">抖音官方号 </span>
                        <div class="imgEl" style="margin-top: 10px;">
                            <el-image style="width: 60px; height: 60px" src="../web/dy.jpg" />
                            <span>扫一扫加关注</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="a3">
                <span>Copyright©2023-2024, 上海冠鹿信息技术有限公司版权 </span>
                <span class="" style="margin-top: 10px;">沪ICP备2024089849号-3</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    name: 'appEnd',
    data() {

    },
    methods: {
        MovenTsgn(index, y) {
            console.log("MovenTsgn", index, y);
            this.$emit('move-click', index, y);
        },
        tabClickChen(index) {
            console.log("触发：", index);
        },
    }
}
</script>
<style scoped lang="scss">
.appEnd {
    width: 100%;
    height: 320px;
    position: relative;

    .img {
        position: absolute;
        left: 0;
        top: 0;
    }
}


.four {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    // background-color: aquamarine;

    .a1 {
        display: flex;
        flex-direction: row;
        justify-content: left;
        height: 130px;

        .end {
            color: rgb(255, 255, 255);
            font-size: 13px;
            height: 100px;

            display: flex;
            flex-direction: column;
            justify-content: start;
            margin-left: 50px;
            text-align: left;
            width: 80px;

            .end1 {
                font-size: 13px;
                font-weight: bold;
            }

            .end2 {
                margin-top: 5px;
                font-size: 11px;
                letter-spacing: 0.1em;
            }

        }

        .end21 {
            font-size: 13px;
            text-align: left;
            width: 200px;
            margin-left: 30px;

            display: flex;
            flex-direction: row;
            justify-content: center;

            .lef {
                width: auto;
                margin-right: 20px;

                .imgEl {
                    background-color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 2px;

                    height: 75px;

                    border-radius: 3.38px;

                    color: #333333;
                    font-size: 8px;

                }


            }

            .rig {
                width: auto;

                .imgEl {
                    background-color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 2px;

                    height: 75px;

                    border-radius: 3.38px;

                    color: #333333;
                    font-size: 8px;
                }
            }

            .end211 {
                font-family: Microsoft YaHei;
                font-size: 13px;
                font-weight: bold;
                line-height: normal;
                letter-spacing: 0.02em;
                font-variation-settings: "opsz" auto;
                color: #FFFFFF;

            }
        }

        .a11 {
            display: flex;
            flex-direction: column;
            justify-content: start;
            margin-left: 50px;
            width: 80px;
            padding-top: 35px;
            height: 60px;

            .a111 {
                font-family: Microsoft YaHei;
                font-size: 13px;
                font-weight: bold;
                line-height: normal;
                letter-spacing: 0.02em;
                font-variation-settings: "opsz" auto;
                color: #FFFFFF;
                text-align: left;
            }

            .a112 {


                margin-top: 10px;

                text-align: left;

                font-family: Microsoft YaHei;
                font-size: 11px;
                font-weight: normal;
                line-height: normal;
                letter-spacing: 0.1em;

                font-variation-settings: "opsz" auto;
                color: #FFFFFF;
            }


        }
    }



    .a3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: Microsoft YaHei;
        font-size: 8px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0.02em;

        font-variation-settings: "opsz" auto;
        color: #A8A6A6;

        margin-top: 20px;
    }
}
</style>