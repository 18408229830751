<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div id="app">
    <router-view>

    </router-view>
  </div>

</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
 width: 100%;
 height: 100%;
}
</style>
