<template>
    <div class="WebHand" :style="{ width: width }">
        <div class="left">
            <el-image style="height: 42px" :src="url" />
        </div>
        <div class="tab">
            <span @click="tabClick(0)">首页</span>
            <div class="tabHdTM" v-if="tab != 0"></div>
            <div class="tabHd" v-if="tab == 0"></div>
        </div>
        <div class="tab">
            <span @click="tabClick(1)">APP下载</span>
            <div class="tabHdTM" v-if="tab != 1"></div>
            <div class="tabHd" v-if="tab == 1"></div>
        </div>
        <div class="tab end">
            <span @click="tabClick(2)">服务信息</span>
            <div class="tabHdTM" v-if="tab != 2"></div>
            <div class="tabHd" v-if="tab == 2"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'WebHand',
    props: ['width', "tab"],
    data() {
        return {
            url: "../web/icon-web.svg",
            fit: "contain",
        }
    },
    methods: {
        tabClick(index) {
            console.log(index);
            this.$emit('tab-click', index);
        }
    }
}
</script>
<style scoped lang="scss">
.WebHand {
    background-color: white;

    height: 60px;
    color: black;

    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;

    position: relative;

    position: fixed;
    top: 0;
    margin-right: 0.8%;
    z-index: 999;
    padding-top: 5px;

    .tab {
        margin-left: 50px;
        font-family: Microsoft YaHei;
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;

        font-variation-settings: "opsz" auto;
        color: #333333;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .tab .tabHd {
        width: 25px;
        border-radius: 15px;
        height: 5px;
        background-color: #01BB74;
        margin-top: 5px;
    }

    .tab .tabHdTM {
        width: 20px;
        border-bottom: 5px solid transparent;
        border-radius: 15px;
        margin-top: 5px;
    }

    .end {
        padding-right: 15%;
    }

    .left {
        position: absolute;
        top: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        left: 15%;

        height: 100%;
    }

}
</style>