<template>
  <div class="index" v-if="root">
    <WebHand @tab-click="tabClickChen" ref="HandIndex" :width="TabWidth" :tab="showPage"></WebHand>
    <HomePage @tab-click="tabClickChen" ref="HomePage" v-show="showPage == 0" :ImgWidth="ImgWidth"></HomePage>
    <AppDown @tab-click="tabClickChen" ref="AppDown" :scroll="scroll" v-show="showPage == 1" :ImgWidth="ImgWidth">
    </AppDown>
    <About @tab-click="tabClickChen" ref="About" v-show="showPage == 2" :ImgWidth="ImgWidth"></About>
  </div>
  <div class="index" v-if="!root">
    <AppView></AppView>
  </div>
</template>

<script>
import WebHand from '../modularization/WebHand.vue'
import HomePage from './homePage.vue'
import AppDown from './appDown.vue'
import About from './About.vue'
import AppView from './appView.vue'


import { useRouter } from 'vue-router'

export default {
  components: {
    WebHand,
    HomePage,
    AppDown,
    About,
    AppView
  },
  name: 'indexPage',
  data() {
    return {
      TabWidth: "1800px",
      ImgWidth: "1800px",
      router: null,
      showPage: 0,
      root: true,
      scroll: 0
    }
  },
  mounted() {
    this.initPageWH();
    this.detectDevice();
    this.pageIndexInit();

    // localStorage.clear();

  },
  methods: {
    detectDevice() {
      if (/Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // 检测到是移动设备  
        console.log('访问者是手机端');
        this.root = false
      } else {
        // 假定为Web端  
        this.root = true
        console.log('访问者是Web端');
      }
    },
    tabClickChen(index, scrollIf) {
      this.showPage = index;
      localStorage.setItem("page", index);
      console.log(index);
      console.log(scrollIf)

      switch (index) {
        case 0:
          console.log(index);
          this.$refs.HomePage.MOVEScroll(scrollIf);
          break;
        case 1:
          console.log(index);
          this.$refs.AppDown.MOVEScroll(scrollIf);
          break;
        case 2:
          console.log(index);
          this.$refs.About.MOVEScroll(scrollIf);
          break;
        default:
          break;
      }
      // console.log(scrollIf);
      // if (scrollIf) {
      //   if (index == 1) {
      //     this.$refs.AppDown.MOVE();
      //   }
      // }

    },
    pageIndexInit() {
      const router = useRouter()
      this.router = router;
      if (localStorage.getItem("page") != null || localStorage.getItem("page") != undefined) {
        this.showPage = localStorage.getItem("page");
      } else {
        localStorage.setItem("page", 0)
        this.showPage = localStorage.getItem("page");
      }
    },
    initPageWH() {
      if (!localStorage.getItem('ydj')) {
        // 执行初始化操作  
        console.log('执行初始化操作');
        this.ImgWidth = window.innerWidth + "px";
        this.TabWidth = (window.innerWidth + 60) + "px";
        console.log(this.ImgWidth, this.TabWidth);

        // 设置已初始化的标记
        localStorage.setItem('innerWidthImg', window.innerWidth + "px");
        localStorage.setItem('innerWidthTab', (window.innerWidth + 60) + "px");
        localStorage.setItem('ydj', true);
      } else {
        console.log('有记录');
        this.ImgWidth = localStorage.getItem('innerWidthImg');
        this.TabWidth = localStorage.getItem('innerWidthTab');
        console.log(this.ImgWidth, this.TabWidth);
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.index {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  overflow: hidden;

}
</style>
