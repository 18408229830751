<template>
  <div class="IndexBody" ref="scrollContainer">
    <ImgView :url="IndexUrl[0]" :ImgWidth="ImgWidth" ImgHeight="700px"></ImgView>
    <ImgView :url="IndexUrl[1]" :ImgWidth="ImgWidth" ImgHeight="1000px"></ImgView>
    <ImgView :url="IndexUrl[2]" :ImgWidth="ImgWidth" ImgHeight="700px"></ImgView>
    <ImgView :url="IndexUrl[3]" :ImgWidth="ImgWidth" ImgHeight="1000px">
      <template v-slot:body>
        <div class="careRow">
          <div class="card one">
            <el-image style="width: 120px; height: 120px;" :src="appDownCode" />
            <div style="color: #333333;margin-top: 10px;">手机扫码下载移动端APP </div>
            <div style="color:#999999;margin-top: 5px;">支持安卓和iOS设备 </div>
          </div>
          <div class="card two" @click="Down(0)">
            <el-image style="width: 90px; height: 90px;" :src="az" />
            <div>安卓下载 </div>
            <el-image style="width: 20px; height: 20px;position: absolute;right: 15px;top: 15px;" :src="down" />
          </div>
          <div class="card two" @click="Down(1)">
            <el-image style="width: 90px; height: 90px;" :src="ios" />
            <div>iOS下载 </div>
            <el-image style="width: 20px; height: 20px;position: absolute;right: 15px;top: 15px;" :src="down" />
          </div>
        </div>
      </template>
    </ImgView>
    <EndView :url="IndexUrl[4]" :ImgWidth="ImgWidth" @move-click="move"></EndView>
  </div>
</template>

<script>
import ImgView from '../modularization/ImgView.vue'
import EndView from '../modularization/EndView.vue'
import { useRouter } from 'vue-router'

export default {
  props: ['ImgWidth', 'scroll'],
  components: {
    ImgView,
    EndView
  },
  name: 'appDown',
  data() {
    return {
      TabWidth: "1800px",
      IndexUrl: [
        "../web/webapp001.webp",
        "../web/webapp002.webp",
        "../web/webapp003.webp",
        "../web/webapp004.webp",
        "../web/09.png",
      ],
      appDownCode: "../web/BDs2vSLpNK.jpg",
      az: "../web/az.webp",
      ios: "../web/ios.webp",
      down: "../web/down.webp",
      sownImg: ["../web/az.webp", "../web/ios.webp", "../web/az-g.webp", "../web/ios-g.webp"],
      router: null,
    }
  },
  mounted() {
    const router = useRouter()
    this.router = router;
  },
  methods: {
    MOVEScroll(y) {
      var that = this;
      setTimeout(function () {
        that.$refs.scrollContainer.scrollTop = that.$refs.scrollContainer.scrollHeight * y;
      }, 100)

      // this.$refs.scrollContainer.scrollTop = y
    },
    move(index, y) {
      switch (index) {
        case 0:
          this.$emit('tab-click', index, y);
          break;
        case 1:
          this.$emit('tab-click', index, y);
          break;
        case 2:
          this.$emit('tab-click', index, y);
          break;
        default:
          break;
      }
    },
    // MOVE() {
    //   console.log("MOVE");
    //   var that = this;
    //   setTimeout(function () {
    //     const container = that.$refs.scrollContainer;
    //     container.scrollTop = that.$refs.scrollContainer.scrollHeight * 0.6;
    //     console.log("MOVE", container.scrollTop, that.$refs.scrollContainer.clientHeight);
    //   }, 50)

    // },
    tabClickChen(index) {
      console.log("触发：", index);

      console.log(this.router);

      // switch (index) {
      //   case 0:  this.router.push({path: '/'});break
      //   case 4:  this.router.push('/app');break
      // }
    },
    Down(index) {
      console.log("下载", index);
      // var that = this;
      // switch (index) {
      //   case 0:
      //     this.az = this.sownImg[2]

      //     setTimeout(function () {
      //       that.az = that.sownImg[0]
      //     }, 500)
      //     break;
      //   case 1:
      //     this.ios = this.sownImg[3]
      //     setTimeout(function () {
      //       that.ios = that.sownImg[1]
      //     }, 500)
      //     break;
      //   default:
      //     break;
      // }

      if (index == 0) {
        window.location.href = "https://res-oss.elist.com.cn/app/android/app.apk"
      } else {
        window.location.href = "https://res-oss.elist.com.cn/app/android/app.apk"
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.index {
  width: 100%;
  height: 100%;
  background-color: aqua;
  position: absolute;
  top: 0;
  left: 0;

  overflow: hidden;

  .IndexBody {
    width: 100%;
    height: 100%;
    overflow-y: scroll;

    margin-top: 60px;

    // background-color: rgb(185, 54, 54);

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }

}


.careRow {
  width: 100%;
  height: 60%;
  background-color: transparent;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  .card {
    box-shadow: -2px 0px 3px 0px rgba(143, 143, 143, 0.1), 6px 5px 3px 0px rgba(143, 143, 143, 0.2);
    border-radius: 26px;

  }

  .one {
    height: 300px;
    width: 350px;
    background-color: rgb(255, 255, 255);
    margin-right: 40px;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .two {
    height: 200px;
    width: 300px;
    margin-left: 20px;
    background-color: rgb(255, 255, 255);

    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>