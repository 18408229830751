// router.js
import { createRouter, createWebHistory } from 'vue-router';
 
// 引入Vue组件2
import Index from '../components/index.vue';
import appDown from '../components/appDown.vue';
// 定义路由
const routes = [
  { path: '/', component: Index },
  { path: '/app', component: appDown },
];
 
// 创建router实例
const router = createRouter({
  history: createWebHistory(),
  routes,
});
 
export default router;