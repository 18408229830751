<template>
  <div class="mode" :style="{ width: ImgWidth, height: height + 'px', backgroundColor: color }">
    <div class="bodySolt">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['ImgWidth', "url", "height", "color"],
  name: 'EmptyView',
  data() {
    return {

    }
  },
  methods: {
    tabClickChen(index) {
      console.log("触发：", index);
    },
  }
}
</script>

<style scoped lang="scss">
.mode {

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  .bodySolt {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
  }
}
</style>