<template>
    <div class="slide">
        <div class="left">
            <el-image style="width: 90px; height: 90px" :src="url" :fit="fit" />
        </div>
        <div class="tab end">
            <el-image style="width: 25px; height: 25px" :src="icon" :fit="fit" @click="tabClick()" />
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            url: "../web/icon.webp",
            icon: "../web/num.svg",
            fit: "contain",
            tab: false
        }
    },
    methods: {
        tabClick() {
            this.tab = !this.tab
            console.log(this.tab);
            this.$emit('open-click', this.tab);
        }
    }
}
</script>
<style scoped>
.slide {
    width: 100%;
    height: 7%;
    background-color: white;
    color: black;

    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;

    position: relative;

    position: fixed;
    top: 0;
    z-index: 999;

}

.tab {
    margin-left: 10px;
    font-family: Microsoft YaHei;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;

    font-variation-settings: "opsz" auto;
    color: #333333;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.end {
    padding-right: 8%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.left {
    position: absolute;
    left: 8%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>